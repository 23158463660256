// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAKyPGapIezyIkH3kSkckPKIWz1E1I2_s0",
  authDomain: "chat-itspar.firebaseapp.com",
  databaseURL: "https://chat-itspar-default-rtdb.firebaseio.com",
  projectId: "chat-itspar",
  storageBucket: "chat-itspar.appspot.com",
  messagingSenderId: "838148495850",
  appId: "1:838148495850:web:43d9ce6c0d702c49ceba93",
};

export const adminEmail = "info@itspar.com";
export const onlyAdmin = true;
export const adminRoomId = "qUJPBQcpmPPdzREIx0TB2md6u123";

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
